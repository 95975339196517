<template>
  <div>
    <el-dialog
      title="开房"
      :visible.sync="showing"
      width="650px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <el-form-item label="用户名" prop="guestName">
            <el-input
              placeholder="请输入用户名"
              v-model="formInline.guestName"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="certNo">
            <el-input
              placeholder="请输入身份证号"
              v-model="formInline.certNo"
              style="width: 100%"
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              placeholder="请输入手机号"
              v-model="formInline.mobile"
              style="width: 100%"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      hotel: true,
      index: null,
      //表单
      formInline: {
        // certNo: "321023199612100821",
        // mobile: "15950968356",
      },
      item: {},
      //表单验证
      rules: {
        guestName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        certNo: [
          {
            required: true,
            message: "请输入身份证号",
            trigger: "blur",
          },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "证件号码格式有误！",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item) {
      // this.formInline = {};
      this.title = item;
      this.showing = true;
      this.item = item;
    },

    createStorage(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.formInline.roomId = this.item.id;
          this.formInline.roomNo = this.item.roomNo;
          postRequest("/checkIn", this.formInline).then((res) => {
            if (res.status == 200) {
              this.$message({
            duration: 5000,
                message: "入住成功!",
                type: "success",
              });
              this.hide();
              this.$refs.formInline.resetFields(); //清空表单
              this.$parent.getroom();
            } else {
              this.$message({
            duration: 5000,
                message: res.message,
                type: "warning",
              });
            }
          });
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
>>> .el-radio-group {
  display: flex;
}
</style>