<template>
  <div class="outer">
    <div class="hl-content">
      <div class="container" @click="clickWrap">
        <div class="right">
          <!-- <div v-show="show" class="loading">
            <el-button type="primary" :loading="true">加载中</el-button>
          </div> -->
          <div class="ftt-top-wrap">
            <div
              v-for="(floor, index) in roomList"
              :key="index"
              @contextmenu.prevent="showMenu($event, floor, index)"
              :class="[
                'room',
                { room1: floor.roomStatus == 'VC' },
                { room2: floor.roomStatus == 'VD' },
                { room3: floor.roomStatus == 'OOO' },
                { room4: floor.roomStatus == 'OC' },
                { room5: floor.roomStatus == 'OD' },
                { choose: floor.choose },
              ]"
              @dblclick="roomControl(floor)"
              @click="batchChange(floor, index)"
            >
              <div class="roomInfo">
                <div class="roomNo">
                  <span class="roomNoName"> {{ floor.roomNo }}</span>
                  <span v-if="floor.deviceVersion"
                    >V{{ floor.deviceVersion }}</span
                  >
                </div>
                <div class="roomName">{{ floor.roomTypeName }}</div>
                <div class="banben">{{ floor.banben }}</div>
              </div>

              <div class="roomBottom">
                <div :class="['bg', { bg1: floor.Flag == 1 }]"></div>
                <div class="roomStateImg">
                  <div
                    v-for="(item1, index2) in floor.serviceStatusList"
                    :key="index2"
                  >
                    <img
                      class="serviceStatus"
                      src="../../img/disturb.png"
                      alt
                      v-show="
                        item1.deviceTypeCode == 'DNDService' &&
                        item1.valueCode == 'TurnOn'
                      "
                    />
                    <img
                      class="serviceStatus"
                      src="../../img/checkOut.png"
                      v-show="
                        item1.deviceTypeCode == 'CheckOutService' &&
                        item1.valueCode == 'TurnOn'
                      "
                      alt
                    />
                    <img
                      class="serviceStatus"
                      v-show="
                        item1.deviceTypeCode == 'ClearService' &&
                        item1.valueCode == 'TurnOn'
                      "
                      src="../../img/clean.png"
                      alt
                    />
                    <img
                      class="serviceStatus"
                      v-show="
                        item1.deviceTypeCode == 'SOSService' &&
                        item1.valueCode == 'TurnOn'
                      "
                      src="../../img/sos.png"
                      alt
                    />
                  </div>
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chooseRoom" v-if="choose">
          <div class="tongjifangliang">
            <span>共：{{ roomList2.length }} 间</span>
            <span class="rightsp">当前：{{ roomList.length }} 间</span>
          </div>
          <!-- <div class="ftsm">
        <span class="ft">房态：</span>
        <div class="ftt-select option" @change="roomStatusChange()">
          <select name v-model="chooseFt">
            <option value="null">全部</option>
            <option
              :value="item.statusDesc"
              v-for="(item, index) in roomStatus"
              :key="index"
            >
              {{ item.statusDesc }}
            </option>
          </select>
        </div>
      </div> -->
          <!-- 房间筛选 -->
          <div class="tongjifangliang2">
            <div class="roomChoose">房间筛选</div>
            <div class="ftt-left-item item1">
              <div
                :class="['all', { checked: louquIndex == '全部' }]"
                @click="louquRadio('全部')"
              >
                全部
              </div>
            </div>
            <div class="limitHight">
              <div class="ftt-left-item item1">
                <div class="ftt-left-item-title">
                  <span>区域</span>
                </div>
                <div
                  :class="[
                    'ftt-left-item-container',
                    { height0: louquZhankai == true },
                  ]"
                >
                  <div
                    v-for="(item, index) in louquList"
                    :key="index"
                    :class="{ checked: louquIndex == index }"
                    @click="louquRadio(index, item)"
                  >
                    <span>{{ item.areaName }}</span>
                  </div>
                </div>
              </div>
              <div class="ftt-left-item item1">
                <div class="ftt-left-item-title">
                  <span>楼层</span>
                </div>
                <div
                  :class="[
                    'ftt-left-item-container',
                    { height0: louquZhankai == true },
                  ]"
                  :style="{ height: areList.length * 1.85 + 'em' }"
                >
                  <div
                    v-for="(item, index) in areList"
                    :key="index"
                    :class="{ checked: loucengIndex == index }"
                    @click="loucengRadio(index, item)"
                  >
                    <span>{{ item.floorName }}</span>
                  </div>
                </div>
              </div>
              <div class="ftt-left-item item1">
                <div class="ftt-left-item-title">
                  <span>房型</span>
                </div>
                <div
                  :class="[
                    'ftt-left-item-container',
                    { height0: louquZhankai == true },
                  ]"
                  :style="{ height: roomTypeList.length * 1.85 + 'em' }"
                >
                  <div
                    v-for="(item, index) in roomTypeList"
                    :key="index"
                    :class="{ checked: fangxingIndex == index }"
                    @click="loutypeRadio(index, item)"
                  >
                    <span>{{ item.typeName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 房态 -->
          <div class="tongjifangliang3">
            <div class="roomChoose">房态</div>
            <div class="roomstat">
              <div class="fwzt">
                <div><span class="roomState roomState1"></span> 空净 VC</div>
                <div><span class="roomState roomState2"></span> 空脏 VD</div>
              </div>
              <div class="fwzt">
                <div><span class="roomState roomState3"></span> 维修 OOO</div>
                <div><span class="roomState roomState4"></span> 住净 OC</div>
              </div>
              <div><span class="roomState roomState5"></span> 住脏 OD</div>
            </div>
          </div>
          <!-- 服务状态 -->
          <div class="tongjifangliang3">
            <div class="roomChoose">服务状态</div>
            <div class="roomstat">
              <div class="fwzt">
                <div>
                  <img class="serviceStatus" src="../../img/disturb.png" alt />
                  勿扰
                </div>
                <div>
                  <img class="serviceStatus" src="../../img/checkOut.png" alt />
                  退房
                </div>
              </div>
              <div class="fwzt">
                <div>
                  <img class="serviceStatus" src="../../img/clean.png" alt />
                  清理
                </div>
                <div>
                  <img class="serviceStatus" src="../../img/sos.png" alt />
                  SOS
                </div>
              </div>
            </div>
          </div>
          <!-- 收起 -->
          <img
            src="../../img/shouqi.png"
            class="float"
            @click="noShow"
            alt=""
          />
        </div>
        <!-- 展开 -->
        <div v-if="!this.choose" @click="Show" class="showFloat">
          <img src="../../img/show.png" alt="" />
        </div>
        <div class="chooseList" v-if="chooseRoomList.length > 0 && showRoom">
          <div
            v-for="(item, index) in chooseRoomList"
            :key="index"
            class="chooseRoomNo"
          >
            {{ item.roomNo }}
          </div>
        </div>
        <!-- 右击弹框 -->
        <div class="menu-wrap" ref="menu" v-show="isShow" id="menu">
          <p
            :class="[
              'menu-wrap-cno',
              { roomState1: getbgcolor == 'VC' },
              { roomState2: getbgcolor == 'VD' },
              { roomState3: getbgcolor == 'OOO' },
              { roomState4: getbgcolor == 'OC' },
              { roomState5: getbgcolor == 'OD' },
            ]"
          >
            {{ frameTitle }}
          </p>
          <div
            @click="getAroom"
            v-if="chooseRoomList.length < 2 && kaifang && roomStatus == 'VC'"
          >
            开房
          </div>
          <div
            @click="roomMaking('VD')"
            v-if="
              chooseRoomList.length < 2 &&
              tuifang &&
              (roomStatus == 'OC' || roomStatus == 'OD')
            "
          >
            退房
          </div>
          <div
            @click="roomMaking('VC')"
            v-if="
              chooseRoomList.length < 2 &&
              (roomStatus == 'VD' || roomStatus == 'OD' || roomStatus == 'OOO')
            "
          >
            制清洁房
          </div>
          <div
            @click="roomMaking('OOO')"
            v-if="
              chooseRoomList.length < 2 &&
              (roomStatus == 'VC' || roomStatus == 'VD')
            "
          >
            制维修房
          </div>
          <div
            @click="roomMaking('OD')"
            v-if="chooseRoomList.length < 2 && roomStatus == 'OC'"
          >
            制脏房
          </div>

          <div
            @click="goToXyd"
            v-if="chooseRoomList.length > 1 && choosePlxgft"
          >
            批量修改房态
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <el-dialog title="批量修改房态" :visible.sync="changeALLRoom" width="30%">
      <el-form
        ref="changeRoom"
        :model="changeRoom"
        @submit.native.prevent
        :rules="rules"
      >
        <!-- <div class="banben"> -->
        <el-form-item label="房态" prop="status">
          <el-select
            class="text"
            style="width: 100%"
            v-model="changeRoom.status"
            placeholder="请选择房态"
          >
            <el-option
              v-for="item in roomStausList"
              :key="item.status"
              :label="item.name"
              :value="item.status"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="sure">
          <el-button
            size="small"
            type="primary"
            class="sureChange"
            @click="createStorage('changeRoom')"
            >确定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <addModify ref="addModify" />
    <!-- <disableModify ref="disableModify" /> -->
  </div>
</template>
<script>
// import { querySelectAllUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";
import addModify from "@/views/roomControl/addModify.vue";
// import disableModify from "@/views/roomState/disableModify.vue";
export default {
  data() {
    return {
      showRoom:false,
      list: [],
      showList: [],
      roomname: "",
      value: "",
      loucengList2: [],
      fangxingList: [],
      chooseRoomList: [],
      louquZhankai: true,
      loucengZhankai: true,
      fangxingZhankai: true,
      louquAll: true,
      loucengAll: true,
      roomTypeAll: true,
      choose: true,
      roomStatus: [],
      chooseFt: null,
      louquList: [],
      areList: [],
      roomTypeList: [],
      louquZhankai: false,
      louquIndex: "全部",
      loucengIndex: null,
      fangxingIndex: null,
      getbgcolor: null,
      roomList2: [],
      // 右侧房间
      roomList: [
        // { roomNo: "1101", roomName: "标准大床房", banben: "V1.3" },
      ],
      deleteVisible: false,
      row: {},
      todelete: "",
      // 右击弹框
      isShow: false,
      frameTitle: "",
      isCtrl: false, // 快捷键 ctrl 是否被按下
      choosePlxgft: false,
      roomStatus: false,
      kaifang: false,
      tuifang: false,
      fjsbzt: false,
      roomInfor: {},
      // 开房
      form: {},
      show: true,
      changeRoom: {},
      changeALLRoom: false,
      roomStausList: [
        {
          name: "空净",
          status: "VC",
        },
        {
          name: "空脏",
          status: "VD",
        },
        {
          name: "维修",
          status: "OOO",
        },
        {
          name: "住净",
          status: "OC",
        },
        {
          name: "住脏",
          status: "OD",
        },
      ],
      rules: {
        statusDesc: [
          {
            required: true,
            message: "请输入房态编码",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择房态",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    addModify,
    // disableModify
  },
  created() {
    if (!this.power.checkedpower("0501001")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // 创建
    this.fjsbzt = this.power.checkedpower("0501002");
    // 修改
    // 删除，禁用
    this.kaifang = this.power.checkedpower("0501004");
    this.tuifang = this.power.checkedpower("0501005");
    this.getroom();

    // this.getFounderList();
    // this.getHotelList();
    // this.getRoomStatusList();
    this.getRoomStatus();
    this.getLouquList();
    this.getAreList();
    this.getRoomTypeList();
    // 获取房间列表
    // this.keyDown();
    this.getCache();
  },
  mounted() {
    // 定时任务方法
    this.$nextTick(() => {
      setInterval(this.getCache, 1000);
    });
  },
  methods: {
    // 监听键盘
    keyDown() {
      // 键盘按下事件
      document.onkeydown = (e) => {
        // 取消默认事件
        e.preventDefault();
        //事件对象兼容
        let e1 =
          e || event || window.event || arguments.callee.caller.arguments[0];
        //  ctrl：17
        switch (e1.keyCode) {
          case 17:
            this.isCtrl = true; // 如果ctrl按下就让他按下的标识符变为true
            break;
        }
      };
      // 键盘抬起事件
      document.onkeyup = (e) => {
        // 取消默认事件
        e.preventDefault();
        //事件对象兼容
        let e1 =
          e || event || window.event || arguments.callee.caller.arguments[0];
        switch (e.keyCode) {
          case 17:
            this.isCtrl = false; // 如果ctrl抬起下就让他按下的标识符变为false
            break;
        }
      };
    },

    showMenu(e, floor, index) {
      this.roomInfor = floor;
      this.choosePlxgft = floor.choose;
      this.roomStatus = floor.roomStatus;
      let menu = document.getElementById("menu");
      let length = menu.children.length;
      if (e.y > 600) {
        this.$refs.menu.style.top = e.y - length * 28 + "px";
        this.$refs.menu.style.left = e.x + "px";
      } else {
        this.$refs.menu.style.left = e.x + "px";
        this.$refs.menu.style.top = e.y + "px";
      }
      this.getbgcolor = floor.roomStatus;
      this.frameTitle = floor.roomNo;
      this.isShow = true;
    },
    // 开房退房
    getAroom() {
      // console.log(this.roomInfor);
      // this.opeanRoomVisible = true;
      this.$refs.addModify.show(this.roomInfor);
    },
    roomMaking(status) {
      let parms = {
        roomId: this.roomInfor.id,
        roomStatus: status,
      };
      postRequest("updateRoomStatus", parms).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "success",
          });
          // this.getroom();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },

    checkOutAroom() {},
    clickWrap() {
      this.isShow = false;
    },
    // 批量修改房态
    goToXyd() {
      this.changeALLRoom = true;
    },
    enterMenu() {},
    leaveMenu() {},
    // 获取缓存图标和背景
    getCache() {
      let roomStatus = JSON.parse(window.localStorage.getItem("roomStatus"));
      let roomIcon = JSON.parse(window.localStorage.getItem("roomIcon"));
      let roomIconClose = JSON.parse(
        window.localStorage.getItem("roomIconClose")
      );
      if (roomStatus && roomStatus.length > 0) {
        roomStatus.forEach((statusItem) => {
          this.roomList.forEach((item) => {
            if (statusItem.roomNo == item.roomNo) {
              item.roomStatus = statusItem.serviceType;
            }
          });
        });
      }

      if (this.$store.state.add == 0) {
        if (roomIcon && roomIcon.length > 0) {
          roomIcon.forEach((IconItem) => {
            this.roomList.forEach((item, index1) => {
              if (IconItem.roomNo == item.roomNo) {
                item.serviceStatusList.forEach((item2, index2) => {
                  if (
                    item2.deviceTypeCode == IconItem.serviceType &&
                    item2.roomId == IconItem.roomId
                  ) {
                    let res = this.roomList[index1].serviceStatusList[index2];
                    console.log(res);
                    res.valueCode = "TurnOn";
                    item.Flag = 1;
                  }
                  if (
                    item2.deviceTypeCode == IconItem.serviceType &&
                    item2.roomNo == IconItem.roomNo
                  ) {
                    let res = this.roomList[index1].serviceStatusList[index2];
                    console.log(res);
                    res.valueCode = "TurnOn";
                    item.Flag = 1;
                  }
                  // else {
                  //   item2.valueCode = "TurnOff";
                  // }
                });
              }
            });
          });
        }
        // if (roomIcon.length == 0) {
        //   console.log(6666);
        //   this.roomList.forEach((item) => {
        //     item.serviceStatusList.forEach((item2) => {
        //       item2.valueCode = "TurnOff";
        //     });
        //   });
        // }
      }
      // if (this.$store.state.add == 1) {
      if (roomIconClose && roomIconClose.length > 0) {
        roomIconClose.forEach((item1) => {
          this.roomList.forEach((item2) => {
            if (item1.roomNo == item2.roomNo) {
              item2.serviceStatusList.forEach((item3) => {
                if (item3.deviceTypeCode == item1.serviceType) {
                  item3.valueCode = "TurnOff";
                }
              });
            }
          });
        });
        // }
      }
      let list1 = this.$store.state.roomList;
      // let list1 = window.localStorage.getItem("roomList");
      list1.forEach((item1) => {
        this.roomList.forEach((item2) => {
          if (item1.roomNo == item2.roomNo) {
            item1 = item2;
          }
        });
      });
      // localStorage.setItem("roomList", JSON.stringify(list1));
      this.$store.state.roomList = list1;
    },

    getRoomTypeList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelType", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.checked = true;
          });
          this.roomTypeList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getLouquList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelArea", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.checked = true;
          });
          this.louquList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getAreList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelFloor", { num: 100000, offset: 1 }).then(
        (res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            res.data.forEach((item) => {
              item.checked = true;
            });
            this.areList = res.data;
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
            });
          }
        }
      );
    },
    getRoomStatus() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatus = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomStatusList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatusList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    //房态查询
    roomStatusChange() {
      if (this.chooseFt == "null") {
        if (this.loucengAll && this.louquAll && this.roomTypeAll != false) {
          this.roomList = this.roomList2;
        } else {
          this.roomList = [];
        }
        return;
      }
      let list = [];
      this.roomList2.forEach((item) => {
        if (item.roomStatus == this.chooseFt) {
          list.push(item);
        }
      });
      if (this.loucengAll && this.louquAll && this.roomTypeAll != false) {
        this.roomList = list;
      } else {
        this.roomList = [];
      }
    },
    // 选择楼区 全部楼区
    louquAllChoose() {
      this.louquAll = !this.louquAll;
      this.louquList.forEach((item) => {
        if (this.louquAll == false) {
          item.checked = false;
          this.roomList = [];
        } else {
          item.checked = true;
          if (this.loucengAll && this.roomTypeAll != false) {
            this.roomList = this.roomList2;
          } else {
            this.roomList = [];
          }
        }
      });
    },
    chooseLouqu(item) {
      item.checked = !item.checked;
      let list = [];
      let list2 = [];
      this.louquList.forEach((item) => {
        if (item.checked == true) {
          list.push(item);
          this.roomList2.forEach((item2) => {
            if (item.areaName == item2.areaName) {
              list2.push(item2);
            }
          });
        }
      });
      if (this.loucengAll && this.roomTypeAll != false) {
        this.roomList = list2;
      } else {
        this.roomList = [];
      }
      if (list.length == this.louquList.length) {
        this.louquAll = true;
      } else {
        this.louquAll = false;
      }
    },
    // 楼层 全部楼层
    loucengAllChoose() {
      this.loucengAll = !this.loucengAll;
      this.areList.forEach((item) => {
        if (this.loucengAll == false) {
          item.checked = false;
          this.roomList = [];
        } else {
          item.checked = true;
          if (this.louquAll && this.roomTypeAll != false) {
            this.roomList = this.roomList2;
          } else {
            this.roomList = [];
          }
        }
      });
    },

    chooseloucenge(item) {
      item.checked = !item.checked;
      let list = [];
      let list2 = [];
      this.areList.forEach((item) => {
        if (item.checked == true) {
          list.push(item);
          this.roomList2.forEach((item2) => {
            if (item.floorName == item2.foorName) {
              list2.push(item2);
            }
          });
        }
      });
      if (this.louquAll && this.roomTypeAll != false) {
        this.roomList = list2;
      } else {
        this.roomList = [];
      }
      if (list.length == this.areList.length) {
        this.loucengAll = true;
      } else {
        this.loucengAll = false;
      }
    },
    // 房型
    fangxingAllChoose() {
      this.roomTypeAll = !this.roomTypeAll;
      this.roomTypeList.forEach((item) => {
        if (this.roomTypeAll == false) {
          item.checked = false;
          this.roomList = [];
        } else {
          item.checked = true;
          if (this.loucengAll && this.louquAll != false) {
            this.roomList = this.roomList2;
          } else {
            this.roomList = [];
          }
        }
      });
    },
    choosefangxing(item) {
      item.checked = !item.checked;
      let list = [];
      let list2 = [];
      this.roomTypeList.forEach((item) => {
        if (item.checked == true) {
          list.push(item);
          this.roomList2.forEach((item2) => {
            if (item.typeName == item2.roomTypeName) {
              list2.push(item2);
            }
          });
        }
      });
      if (this.loucengAll && this.louquAll != false) {
        this.roomList = list2;
      } else {
        this.roomList = [];
      }
      if (list.length == this.roomTypeList.length) {
        this.roomTypeAll = true;
      } else {
        this.roomTypeAll = false;
      }
    },
    getroom() {
      // let room = JSON.parse(window.localStorage.getItem("roomList"));
      let room = [];
      if (this.$store.state.roomList.length > 0) {
        room = this.$store.state.roomList;
        this.roomList = room;
        this.roomList2 = room;
      } else {
        this.room();
      }

      return;
      let loadingInstance = this.$loading({ text: "加载中..." }); //如果这数据不存在，则要去接口请求数据
      postRequest("/selectAllRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.choose = false;
            console.log(444, item);
            let allFlag = 0;
            let list = [];
            item.serviceStatusList.forEach((item2) => {
              console.log(item2.valueCode);
              if (item2.valueCode == "TurnOn") {
                list.push(item2);
              }
            });
            if (list.length > 0) {
              allFlag = 1;
            } else {
              allFlag = 0;
            }
            item.Flag = allFlag;
          });
          this.roomList = res.data;
          this.roomList2 = res.data;
          this.show = false;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },

    room() {
      postRequest("/selectAllRoomStatus", {}).then((res) => {
        if (res.status == 200) {
          res.data.forEach((item) => {
            item.choose = false;
            let allFlag = 0;
            let list = [];
            item.serviceStatusList.forEach((item2) => {
              if (item2.valueCode == "TurnOn") {
                list.push(item2);
              }
            });
            if (list.length > 0) {
              allFlag = 1;
            } else {
              allFlag = 0;
            }
            item.Flag = allFlag;
          });
          this.$store.state.roomList = res.data;
          this.roomList = res.data;
          this.roomList2 = res.data;
          this.$store.state.roomTableList = [];
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    roomControl(item) {
      if (this.fjsbzt) {
        this.$router.push({
          name: "roomBasicSetting",
          query: item,
        });
      }

      return;
      console.log(item.id);
      let roomId = item.id;
      this.$refs.addModify.show(roomId);
    },
    // 区域选择
    louquRadio(index, item) {
      if (index == "全部") {
        this.foorName = null;
        this.areaName = null;
        this.loucengIndex = null;
        this.fangxingIndex = null;
        this.getroom();
        this.louquIndex = index;
        return;
      }
      this.louquIndex = index;
      this.areaName = item.areaName;
      let list = [];
      this.roomList2.forEach((item2) => {
        if (item.areaName == item2.areaName) {
          if (this.foorName) {
            if (item2.foorName == this.foorName) {
              if (this.roomTypeName) {
                if (item2.roomTypeName == this.roomTypeName) {
                  list.push(item2);
                }
              } else {
                list.push(item2);
              }
            }
          } else if (this.roomTypeName) {
            if (item2.roomTypeName == this.roomTypeName) {
              if (this.foorName) {
                if (item2.foorName == this.foorName) {
                  list.push(item2);
                }
              } else {
                list.push(item2);
              }
            }
          } else {
            list.push(item2);
          }
        }
      });
      this.roomList = list;
    },
    // 楼层选择
    loucengRadio(index, item) {
      if (this.louquIndex == "全部") {
        this.louquIndex = null;
      }
      this.loucengIndex = index;
      this.foorName = item.floorName;
      let list = [];
      this.roomList2.forEach((item2) => {
        if (item.floorName == item2.foorName) {
          if (this.areaName) {
            if (item2.areaName == this.areaName) {
              if (this.roomTypeName) {
                if (item2.roomTypeName == this.roomTypeName) {
                  list.push(item2);
                }
              } else {
                list.push(item2);
              }
            }
          } else if (this.roomTypeName) {
            if (item2.roomTypeName == this.roomTypeName) {
              if (this.areaName) {
                if (item2.areaName == this.areaName) {
                  list.push(item2);
                }
              } else {
                list.push(item2);
              }
            }
          } else {
            list.push(item2);
          }
        }
      });
      this.roomList = list;
    },
    loutypeRadio(index, item) {
      if (this.louquIndex == "全部") {
        this.louquIndex = null;
      }
      this.fangxingIndex = index;
      this.roomTypeName = item.typeName;
      this.roomType = item.id;
      let list = [];
      this.roomList2.forEach((item2) => {
        if (item.typeName == item2.roomTypeName) {
          // return;
          if (this.foorName) {
            if (item2.foorName == this.foorName) {
              if (this.areaName) {
                if (item2.areaName == this.areaName) {
                  list.push(item2);
                }
              } else {
                list.push(item2);
              }
            }
          } else if (this.areaName) {
            if (item2.areaName == this.areaName) {
              if (this.foorName) {
                if (item2.foorName == this.foorName) {
                  list.push(item2);
                }
              } else {
                list.push(item2);
              }
            }
          } else {
            list.push(item2);
          }
        }
      });
      this.roomList = list;
    },
    //批量
    batchChange(item, id) {
      // if (this.isCtrl) {
      item.choose = !item.choose;
      let chooseList = [];
      this.roomList.forEach((item1) => {
        if (item.id == item1.id) {
          item1 = item;
        }
        if (item1.choose) {
          chooseList.push(item1);
        }
      });
      this.chooseRoomList = chooseList;
      // }
    },
    createStorage(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          var roomIds = "";
          this.chooseRoomList.forEach((item) => {
            roomIds += item.id + ",";
          });
          if (roomIds.length > 0) {
            roomIds = roomIds.substr(0, roomIds.length - 1);
          }
          let parms = {
            roomIds: roomIds,
            targetStatus: this.changeRoom.status,
          };
          postRequest("/updateRoomStatusBatch", parms).then((res) => {
            if (res.status == 200) {
              this.$message({
                duration: 5000,
                message: res.message,
                type: "success",
              });
              this.changeALLRoom = false;
              this.chooseRoomList = [];
              this.roomList.forEach((item) => {
                item.choose = false;
              });
            } else {
              this.$message({
                duration: 5000,
                message: res.message,
                type: "warning",
              });
            }
          });
        } else {
          this.$message({
            duration: 5000,
            message: "请选择目标房态！",
            type: "warning",
          });
        }
      });
    },
    // 隐藏
    noShow() {
      this.choose = false;
    },
    Show() {
      this.choose = true;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.outer {
  height: 100%;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 8%;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.el-card {
  position: relative;
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  position: absolute;
  right: 20px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #f5f6fa;
  height: 100%;
  /* padding: 21px 32px; */
  position: relative;
  /* overflow: scroll; */
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.block {
  position: absolute;
  bottom: 2px;
  right: 32px;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.addIdType2 {
  width: 100px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.right {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  /* background: #5ec3c3; */
}
.chooseRoom {
  background: #ffff;
  width: 250px;
  height: 100%;
  left: 10px;
  bottom: 20px;
  padding: 1%;
  margin-left: 10px;
  /* position: relative; */
}
.tongjifangliang {
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  height: 3%;
  color: rgba(0, 0, 0, 0.45);
}
.tongjifangliang2 {
  height: 62%;
}
.tongjifangliang3 {
  height: 14%;
}
.fwzt > div {
  width: 50%;
  display: flex;
  align-items: center;
}
.tongjifangliang span {
  /* width: 32%; */
  display: inline-block;
}
.tongjifangliang span:first-child {
  margin-right: 20px;
}
.ftt-input2 {
  position: relative;
}

.ftt-input2 > div {
  position: absolute;
  width: 28px;
  height: 28px;
  right: 0;
  top: 1px;
  /* background: red; */
  /* background-image: url("../../assets/image/search2.png"); */
}
.el-select {
  outline: none;
}
.ftt-left-item {
  width: 100%;
  /* height: 23%; */
  /* border: 1.5px solid #76a8f5; */
  border-radius: 2px;
  font-size: 0.8em;
  color: #575f6b;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  /* box-shadow: 4px 4px 10px #b9ccec, -4px -4px 10px #ffffff; */
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
  border-radius: 0.8em;
  overflow: hidden;
  flex-shrink: 0;
}
.ftt-left-item span {
  white-space: nowrap;
  text-indent: 0.5em;
}
.ftt-left-item-title {
  padding: 0 0em 0em 0em;
  height: 1.8em;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  font-weight: bold;
  /* border-bottom: 1px solid #f1f1f2; */
}
.ftt-left-item-title img {
  flex-shrink: 0;
  cursor: pointer;
}
.ftt-left-item-title img:hover {
  transform: scale(1.2);
}
.ftt-left-item-container {
  /* box-shadow: 2px 2px 4px #b9ccec inset, -2px -2px 4px #ffffff inset; */
  /* flex: 1; */
  padding-top: 0.2em;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s;
}
.ftt-left-item-container div {
  padding-left: 1em;
  width: 100%;
  height: 1.8em;
  line-height: 1.8em;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* border-bottom: 1px solid #f1f1f2; */
}
.ftt-left-item-container div img:hover {
  transform: scale(1.1);
}
.select {
  margin-bottom: 10px;
}
.el-select {
  /* height: 30px; */
  border: none;
}
.ftsm {
  display: flex;
  height: 28px;
  min-height: 28px;
  line-height: 28px;
  margin-bottom: 12px;
  justify-content: space-between;
}
.option select {
  color: #6d798e;
  font-size: 14px !important;
  border-radius: 2px;
  border: none;
  outline: none;
  width: 100%;
  height: 28px;
  line-height: 28px;
  border: 1px solid #76a8f5;
  outline: none;
  box-shadow: 2px 2px 4px #b1c9f1 inset, -2px -2px 4px #ffffff inset;
  padding-left: 10px;
}
.option {
  width: 70%;
}
.ft {
  width: 30%;
}
.ftt-top-wrap {
  overflow-y: scroll;
  padding: 3% 0 0 3%;
  display: flex;
  /* height: 90%; */
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-bottom: 1%;
}
/* .room:hover {
  transform: scale(1.1);
} */
.ftt-top {
  /* height: 83%; */
  margin-bottom: 1%;
  /* overflow: auto; */
  /* border:1px solid red; */
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 80px;
  height: 80px;
  /* overflow: hidden; */
}
.ftt-top-item {
  /* width: 9%; */
  height: 0;
  /* padding-bottom: 9%; */
  position: relative;
  margin-right: 1%;
  margin-bottom: 1%;
  overflow: hidden;
  box-shadow: 6px 6px 12px #b9cae6, 0px 0px 0px #ffffff;
  border-radius: 11px;
  overflow: hidden;
  /* box-shadow: 8px 8px 16px #b9ccec, -8px -8px 16px #ffffff; */
  /* border: 2px solid #e7f0ff; */
}
.room {
  width: 14%;
  /* height: 16%; */
  margin-bottom: 20px;
  /* background: #65dd8d; */
  padding: 1.5%;
  padding-bottom: 3%;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 20px;
  position: relative;
}
.room1 {
  background: #65dd8d;
  border: 4px solid #65dd8d;
  box-sizing: border-box;
}
.room2 {
  background: #fdb117;
  border: 4px solid #fdb117;
  box-sizing: border-box;
}
.room3 {
  background: #f5596e;
  border: 4px solid #f5596e;
  box-sizing: border-box;
}
.room4 {
  background: #59c4f5;
  border: 4px solid #59c4f5;
  box-sizing: border-box;
}
.room5 {
  background: #7977f3;
  border: 4px solid #7977f3;
  box-sizing: border-box;
}
.check1 {
  position: absolute;
  width: 100%;
  height: 100%;
}
.roomNo {
  width: 100%;
  /* text-align: left; */
  font-size: 1rem;
  line-height: 22px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}
.bg {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.15;
}
.bg1 {
  background-color: #000000;
}
.roomBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
}
.roomNoName {
  font-weight: bolder;
}
.roomName {
  width: 100%;
  text-align: left;
  font-size: 1rem;
  line-height: 22px;
  color: #fff;
  margin-top: 1%;
}
.roomInfo {
  height: 75%;
}
.roomStateImg {
  position: absolute;
  width: 100%;
  padding: 0 16px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  /* z-index: 9999; */
}
.banben {
  position: absolute;
  right: 14px;
  top: 14px;
  line-height: 22px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.45;
}
.roomTypeName {
  color: #575f6b;
  font-size: 1em;
  transform: scale(0.8);
  font-weight: bold;
  position: absolute;
  bottom: 3%;
  white-space: nowrap;
}
.outer {
  position: relative;
  height: 100%;
}
.roomChoose {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
  margin: 20px 0 10px;
}
.float {
  position: absolute;
  font-weight: bold;
  right: 215px;
  bottom: 28%;
}

.checked {
  color: #000000;
  background-color: #cdcdcd;
}
.roomstat {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}
.roomstat > div {
  line-height: 25px;
  display: flex;
  align-items: center;
}
.roomState {
  display: inline-block;
  width: 16px;
  height: 16px;
  opacity: 1;
  border-radius: 12px;
  margin-right: 10px;
}
.serviceStatus {
  display: inline-block;
  width: 16px;
  height: 16px;
  opacity: 1;
  /* border-radius: 12px; */
  margin-right: 9px;
}
.roomState1 {
  background: #65dd8d;
}
.roomState2 {
  background: #fdb117;
}
.roomState3 {
  background: #f5596e;
}
.roomState4 {
  background: #59c4f5;
}
.roomState5 {
  background: #7977f3;
}
.choose {
  border: 4px solid rgb(128, 131, 236);
  box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.showFloat {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  bottom: 28%;
  right: 0;
}
.menu-wrap {
  position: fixed;
  width: 160px;
  /* border:1px solid #666; */
  border-radius: 5px;
  box-shadow: 8px 8px 10px #d7ecfb, -3px -3px 10px #ffffff;
  background: #f5f6fa;
  font-size: 14px;
  z-index: 9999;
}
.menu-wrap-cno {
  color: white;
  line-height: 30px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.menu-wrap > div {
  line-height: 28px;
  height: 28px;
  padding-left: 10px;
  border-bottom: 1px solid #dfdfdf;
  cursor: pointer;
}
.menu-wrap > div:hover {
  background: #bfe3ff;
  color: #fff;
}
/* .chooseList {
  position: absolute;
  right: 30%;
  top: 15%;
} */
.chooseList {
  overflow: auto;
  color: #666;
  font-weight: 700;
  line-height: 28px;
  position: fixed;
  top: 10%;
  right: 3%;
  width: 80px;
  box-shadow: 2px 2px 4px #b1c9f1, -2px -2px 4px #fff;
  background: #fff;
  border-radius: 8px;
  padding: 10px 0;
  max-height: 300px;
  opacity: 0.8;
}
.chooseRoomNo {
  text-align: center;
}
.all {
  cursor: pointer;
  padding: 5px 8px;
}
.limitHight {
  height: 93%;
  overflow: scroll;
}
.loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2fa5dc;
}
.loadingImg {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}
.el-button--primary {
  font-size: 20px;
  color: #2fa5dc;
  /* color: #fff; */
  background-color: #f5f6fa;
  border-color: #f5f6fa;
}
.sure {
  position: relative;
  height: 30px;
  margin-top: 10px;
}
.sureChange {
  font-size: 16px;
  margin-top: 10px;
  width: 100px;
  height: 35px;
  background: #387dff;
  color: #fff;
  border-radius: 4px;
  position: absolute;
  right: 0;
}
</style>